import React, { useState } from 'react';
import { Link, Router } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { useTypewriter, Cursor, Typewriter } from "react-simple-typewriter";
import Skillcard from './Skillcard';
import Socialcard from './Socialcard';
import Groupcard from './Groupcard';
import Projects from './Projects';
import Certcard from './Certcard';

const Home = () => {
    const jobList = ['a Student.', 'a Full Stack Developer.', 'a Researcher.', 'a Designer.', 'a Product Manager.', 'a CEO.', 'an Entrepreneur.', 'a Google Product Expert.', 'a Leader of Team SCIAN.', 'CEO of Goding.', 'JeongHoon Lim.'];
    const [sidebarOpen, setSidebarOpen] = useState(true);
    // const [lastDeployedTime, setLastDeployedTime] = useState('');
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }
    // useEffect(() => {
    //     const fetchDeploymentTime = async () => {
    //         try {
    //             const accessToken = process.env.REACT_APP_VERCEL_API_KEY;
    //             console.log(accessToken);
    //             const response = await fetch(
    //                 `https://api.vercel.com/v6/deployments?projectId=prj_94hAdAcl5uLavblwNVWZwxJTjMhO&target=production&limit=2`,
    //                 {
    //                     method: 'GET',
    //                     headers: {
    //                         Authorization: `Bearer ${accessToken}`,
    //                     }
    //                 }
    //             );

    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }

    //             const deployments = await response.json().deployments;
    //             const latestDeployment = deployments[0]; // Assuming the deployments are sorted in descending order
    //             const deploymentId = latestDeployment.uid;

    //             const deploymentResponse = await fetch(
    //                 `https://api.vercel.com/v13/deployments/${deploymentId}`,
    //                 {
    //                     method: 'GET',
    //                     headers: {
    //                         Authorization: `Bearer ${accessToken}`,
    //                     }
    //                 }
    //             );

    //             if (!deploymentResponse.ok) {
    //                 throw new Error('Network response was not ok');
    //             }

    //             const data = await deploymentResponse.json();
    //             const readyTimestamp = data.ready;
    //             const formattedTime = new Date(readyTimestamp).toLocaleString();
    //             setLastDeployedTime(formattedTime);

    //             // if (!response.ok) {
    //             //     throw new Error('Network response was not ok');
    //             // }

    //             // const data = await response.json();
    //             // const readyTimestamp = data.ready;
    //             // const formattedTime = new Date(readyTimestamp).toLocaleString();
    //             // setLastDeployedTime(formattedTime);
    //         } catch (error) {
    //             console.error('Error fetching deployment time:', error);
    //             // Handle the error appropriately, e.g., set a default value for lastDeployedTime
    //             setLastDeployedTime('N/A');
    //         }
    //     };

    //     fetchDeploymentTime();
    // }, []);
    return (
        <div>
            <Element name="home" className="h-screen bg-cover bg-center relative flex items-center justify-center text-white section"
                style={{ backgroundImage: `url('./profile.jpeg')` }}>
                <div className="absolute inset-0 bg-black bg-opacity-55 backdrop-blur-md flex items-center justify-center">
                    <div className="text-center">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">JeongHoon Lim <i
                            className="bi bi-patch-check-fill ml-2"></i></h1>
                        <p className="text-center text-xl">
                            I'm&nbsp;
                            <Typewriter
                                words={jobList}
                                loop={20}
                                cursor
                                cursorStyle='|'
                                typeSpeed={70}
                                deleteSpeed={50}
                                delaySpeed={1000}
                            />
                        </p>
                        <ScrollLink to="groups" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div>Learn More <br /><i className="bi bi-chevron-down"></i></div>
                        </ScrollLink>
                    </div>
                </div>
            </Element>
            <Element name="groups" className="bg-white py-20 min-h-screen">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full justify-center items-start text-center md:text-left">
                        <h2 className="text-3xl font-bold mb-4">Groups</h2>
                        <p className="w-full">제가 속해 있는 단체를 소개드립니다.</p>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-4 gap-8">
                            <Groupcard groupName="대전동신과학고등학교" groupDescription="대전동신과학고등학교 9기 학생으로 재학중입니다." imgLink="https://i.imgur.com/0QdeuWt.png" link="https://ddsciencehs.djsch.kr/" style="bg-slate-400" />
                            <Groupcard groupName="KAIST CCE" groupDescription="KAIST IP영재기업인교육원 11기를 우수하게 수료하였습니다." imgLink="https://i.imgur.com/bwmX1w0.png" link="https://ipceo.kaist.ac.kr/" style="bg-blue-300" />
                            <Groupcard groupName="갓잉" groupDescription="공유 중심의 공부 계획 및 기록 플랫폼을 만들어나가는 갓잉의 대표입니다." imgLink="https://assets.goding.us/logo/logo_white.svg" link="https://goding.us/" style="bg-[#2f54d2]" />
                            <Groupcard groupName="Team SCIAN" groupDescription="다양한 소프트웨어 프로젝트를 진행하는 팀 SCIAN의 리더입니다." imgLink="https://i.imgur.com/Dv9pOCi.png" link="https://github.com/scianteam" style="bg-gray-200" />
                            <Groupcard groupName="Google Product Expert" groupDescription="Google 공인 제품 전문가 그룹의 일원으로 참여하고 있습니다." imgLink="https://i.imgur.com/k70Lh4s.png" link="https://productexperts.withgoogle.com/" style="bg-yellow-500" />
                            <Groupcard groupName="동신과고 방송부" groupDescription="대전동신과학고등학교의 방송부장으로 활동하고 있습니다." imgLink="https://i.imgur.com/OlvISv1.png" link="https://www.youtube.com/@ddshs" style="bg-blue-200" />
                            <Groupcard groupName="Accel Network" groupDescription="영재기업인 수료생 네트워크 Accel의 일원으로 참여중입니다." imgLink="https://cdn.imweb.me/upload/S202105246dda1d33bf0c3/a02a0bc8e237e.png" link="http://accel.or.kr/" style="bg-slate-300" />
                            <Groupcard groupName="AppleSeed Program" groupDescription="초대 한정 AppleSeed 베타 프로그램의 일원으로 참여하고 있습니다." imgLink="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Apple_logo_white.svg/1200px-Apple_logo_white.svg.png" link="https://appleseed.apple.com/" style="bg-gray-800" />
                            <Groupcard groupName="Google Apps Community Insights" groupDescription="개발자 리서치 커뮤니티 프로그램인 Google ACI 1,2기로 참여하였습니다." imgLink="https://i.imgur.com/1nvnAk2.png" link="https://google.cspace.com/" style="bg-white" />
                            <Groupcard groupName="Devvit" groupDescription="Reddit의 비공개 개발자 커뮤니티인 Devvit의 일원으로 참여하여 확장 앱을 개발합니다." imgLink="https://i.imgur.com/mzieIfv.png" link="https://developers.reddit.com/" style="bg-[#FF4500]" />
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="socials" className="bg-slate-100 py-20 min-h-screen">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full justify-center items-start text-center md:text-left">
                        <h2 className="text-3xl font-bold mb-4">Socials</h2>
                        <p className="w-full">소셜 미디어와 개발, 음악 관련 프로필입니다.</p>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-2 md:grid-cols-6 gap-8">
                            <Socialcard icon="fa-brands fa-linkedin" title="LinkedIn" label="@hoony6134" link="https://linkedin.com/in/hoony6134" bgColor="bg-blue-600" verified verifiedStyle="text-blue-600" />
                            <Socialcard icon="bi bi-github" title="GitHub" label="@hoony6134" link="https://github.com/hoony6134" bgColor="bg-black" />
                            <Socialcard icon="bi bi-instagram" title="Instagram" label="@_jh_0105" link="https://instagram.com/_jh_0105" bgColor="bg-gradient-to-r from-pink-500 to-pink-600" />
                            <Socialcard icon="fa-brands fa-spotify" title="Spotify" label="Cyan" link="https://open.spotify.com/artist/73DiCrbvla9WAvdUvwuznm" bgColor="bg-green-500" verified verifiedStyle="text-blue-500" />
                            <Socialcard icon="fa-brands fa-mastodon" title="Mastodon" label="@scian" link="https://mastodon.social/@scian" bgColor="bg-purple-700" verified verifiedStyle="text-purple-700" />
                            <Socialcard icon="fa-solid fa-globe" title="Blog" label="scian.xyz" link="https://scian.xyz" bgColor="bg-yellow-400" />
                            <Socialcard icon="fa-solid fa-at" title="Email" label="Click to reveal" link="https://mailhide.io/e/B2zyI8sp" bgColor="bg-blue-400" />
                            <Socialcard icon="fa-brands fa-discord" title="Discord" label="@scian_" link="https://discord.com/users/695454523518615564" bgColor="bg-blue-700" />
                            <Socialcard icon="fa-brands fa-steam" title="Steam" label="CYAN" link="https://steamcommunity.com/id/scian_/" bgColor="bg-slate-600" />
                            <Socialcard icon="fa-brands fa-reddit" title="Reddit" label="u/applr_" link="https://reddit.com/u/applr_" bgColor="bg-red-600" />
                            <Socialcard icon="fa-brands fa-google-play" title="Google Play" label="Scian" link="https://play.google.com/store/apps/dev?id=6515470085947911101" bgColor="bg-green-600" />
                            <Socialcard icon="fa-brands fa-stack-overflow" title="Stack Overflow" label="Cyan" link="https://stackoverflow.com/users/15921510/cyan" bgColor="bg-orange-500" />
                            <Socialcard icon="fa-brands fa-dev" title="Dev.to" label="@hoony6134" link="https://dev.to/hoony6134" bgColor="bg-black" />
                            <Socialcard icon="fa-solid fa-code" title="Google Dev" label="Cyan" link="https://g.dev/cyan" bgColor="bg-blue-500" />
                            <Socialcard icon="fa-brands fa-chrome" title="Web Store" label="scian.xyz" link="https://chromewebstore.google.com/detail/languard/ifgpkoomfngpmnjmoaidmkmmjjkdhfmn" verified verifiedStyle="text-blue-500" bgColor="bg-yellow-500" />
                            <Socialcard icon="fa-brands fa-soundcloud" title="SoundCloud" label="Cyan" link="https://soundcloud.com/cyan_official" bgColor="bg-orange-400" />
                            <Socialcard icon="fa-brands fa-paypal" title="PayPal" label="임정훈" link="https://paypal.me/cyanofficial" bgColor="bg-blue-800" verified verifiedStyle="text-blue-800" />
                            <Socialcard icon="bi bi-cup-hot-fill" title="Buy Me a Coffee" label="Cyan" link="https://www.buymeacoffee.com/scian" bgColor="bg-yellow-300" verified verifiedStyle="text-yellow-300" />
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="projects" className="bg-white py-20 min-h-screen">
                <Projects />
            </Element>
            <Element name="stacks" className="bg-slate-50 py-20 min-h-screen">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full justify-center items-start text-center md:text-left">
                        <h2 className="text-3xl font-bold mb-4">Skills</h2>
                        <p className="w-full">제가 보유하고 있는 스킬셋입니다. 아래에는 스킬별 간단한 설명만 적혀 있으며, 역량에 대한 증명 및 관련 프로젝트는 위 프로젝트 섹션을 참고해 주세요.</p>
                        <p className="w-full mb-8">체크마크 (<i className="bi bi-patch-check-fill text-blue-500"></i>)는 해당 스킬과 관련하여 수상하였거나, 유효한 자격증 혹은 증명서, 수료증 등을 보유하고 있을 경우 표시하였습니다.</p>
                        <span className="font-bold text-xl mr-2">자격증 및 수료증</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            {/* <Certcard title="Google Cloud Compute Engine" issuer="Google Cloud X Coursera" issuerimg="https://www.langoly.com/wp-content/uploads/2021/09/coursera-logo.png" skillname="Google Cloud Platform" skillicon="fa-brands fa-google" style="bg-blue-200" /> */}
                            <a href="https://github.com/hoony6134/share/blob/98db04506ddab62f942016fa04ff90466a0e9242/AICC%20Certificate%20(Upload).pdf" target="_blank" className="text-black hover:text-gray-800"><Certcard title="Advanced International Certificate Course" issuer="KIPO-WIPO-KAIST-KIPA" issuerimg="https://www.itu.int/net4/wsis/ungis/Content/img/logos/uniform/wipo.png" skillname="특허 및 지적 재산권" skillicon="fa-solid fa-globe" style="bg-slate-300" /></a>
                            <Certcard title="두산연강재단 장학생" issuer="두산연강재단" issuerimg="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Doosan_logo.svg/336px-Doosan_logo.svg.png" skillname="과학 및 연구" skillicon="fa-solid fa-flask" style="bg-blue-200" />
                            <Certcard title="GTQ 1급" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="Adobe Photoshop" skillicon="devicon-photoshop-plain" />
                            <Certcard title="ITQ 아래한글 A등급" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="한컴오피스 한글" skillicon="fa-regular fa-file-word" />
                            <Certcard title="ITQ 액셀 A등급" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="Excel" skillicon="fa-regular fa-file-excel" />
                            <Certcard title="ITQ 파워포인트 A등급" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="PowerPoint" skillicon="fa-regular fa-file-powerpoint" />
                            <Certcard title="ITQ 인터넷 A등급" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="정보 검색 및 처리" skillicon="bi bi-info-circle" />
                            <Certcard title="ITQ OA Master" issuer="한국생산성본부" issuerimg="https://i.imgur.com/jyhJ7VQ.png" skillname="사무 자동화" skillicon="fa-solid fa-file-alt" />
                            <a href="https://www.coursera.org/account/accomplishments/certificate/RCTTM96CSLAG" target="_blank" className="text-black hover:text-gray-800"><Certcard title="Hosting a Web App with Google Compute Engine" issuer="Google Cloud X Coursera" issuerimg="https://i.imgur.com/3799ZPv.png" skillname="Google Cloud Platform" skillicon="fa-brands fa-google" style="bg-blue-100" /></a>
                        </div>
                        <br />
                        <span className="font-bold text-xl mr-2">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>Web & Server</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="fa-brands fa-html5" title="HTML5" desc="HTML5를 이용하여 웹 페이지를 구현할 수 있습니다." styleClass="bg-gradient-to-r from-orange-500 to-orange-600 text-white" progress="76" />
                            <Skillcard icon="fa-brands fa-css3-alt" title="CSS3" desc="CSS3를 이용하여 웹 페이지를 디자인할 수 있습니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-600 text-white" progress="35" />
                            <Skillcard icon="fa-brands fa-js-square" title="JavaScript" desc="JavaScript를 이용하여 웹 페이지를 동적으로 구현할 수 있습니다." styleClass="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white" progress="45" />
                            <Skillcard icon="fa-brands fa-react" title="React" desc="React를 이용하여 웹 페이지를 구현할 수 있습니다. 본 포트폴리오 또한 React를 이용해 만들어졌습니다." styleClass="bg-gradient-to-r from-blue-400 to-blue-500 text-white" progress="50" />
                            <Skillcard icon="fa-brands fa-node-js" title="Node.js" desc="Node.js를 이용하여 API 서버를 직접 구현해 보았으며, HTTPS 기반 서버-클라이언트 통신을 구현했습니다." styleClass="bg-gradient-to-r from-green-400 to-green-500 text-white" progress="65" />
                            <Skillcard icon="fa-solid fa-database" title="MySQL" desc="MySQL을 이용하여 데이터베이스를 구축하고, API 서버와 연결 및 통신하도록 구현할 수 있습니다." styleClass="bg-gradient-to-r from-gray-600 to-gray-700 text-white" progress="50" />
                            <Skillcard iconImage="https://www.loopple.com/img/tailwind-logo.png" title="TailwindCSS" desc="TailwindCSS를 이용하여 본 포트폴리오와 같은 웹 페이지를 디자인할 수 있습니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-300 text-white" progress="75" />
                        </div>
                        <span className="font-bold text-xl mr-2">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>App Development & Language</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="fa-brands fa-swift" title="Swift" desc="Swift 및 SwiftUI를 이용하여 iOS용 애플리케이션의 백엔드 및 프론트엔드를 다루는 여러 프로젝트를 진행하였습니다." styleClass="bg-gradient-to-r from-red-500 to-yellow-500 text-white" progress="55" />
                            <Skillcard icon="fa-brands fa-python" title="Python" desc="Python과 관련 라이브러리를 이용하여 인공지능 및 GUI를 비롯한 여러 프로젝트를 진행하였습니다." styleClass="bg-gradient-to-r from-blue-500 to-green-500 text-white" progress="60" />
                            <Skillcard icon="fa-solid fa-c" title="Clang" desc="C언어를 이용하여 간단한 프로젝트를 진행하였으며, 기본적인 문법을 이해하고 있습니다." styleClass="bg-gradient-to-r from-gray-500 to-gray-600 text-white" progress="20" />
                            <Skillcard icon="fa-brands fa-android" title="Android" desc="Android Studio를 이용하여 안드로이드 애플리케이션의 백엔드 및 프론트엔드 관리의 기본을 배웠습니다." styleClass="bg-gradient-to-r from-green-500 to-green-600 text-white" progress="10" />
                        </div>
                        <span className="font-bold text-xl mr-2">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>DevOps</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="fa-brands fa-google" title="Google Cloud" isVerified desc="Google Cloud Platform을 이용하여 인스턴스를 관리하고, 다양한 클라우드 기능을 사용할 수 있습니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-300 text-white" progress="30" />
                            <Skillcard iconImage="https://samhorn.com/wp-content/uploads/2021/06/oracle-logo-large-white-1.png" title="Oracle Cloud" desc="Oracle Cloud Infrastructure를 이용하여 인스턴스를 관리하고, 다양한 클라우드 기능을 사용할 수 있습니다." styleClass="bg-gradient-to-r from-red-500 to-red-300 text-white" progress="30" />
                            <Skillcard icon="fa-brands fa-git-alt" title="Git" desc="GitHub와 함께, Git을 이용하여 버전 관리 및 협업을 할 수 있습니다." styleClass="bg-gradient-to-r from-red-500 to-red-600 text-white" progress="60" />
                            <Skillcard icon="fa-brands fa-github" title="GitHub" desc="GitHub를 이용하여 Git 저장소 및 오픈소스 프로젝트를 관리하고, 협업을 진행하였습니다." styleClass="bg-gradient-to-r from-gray-800 to-gray-900 text-white" progress="70" />
                            <Skillcard icon="fa-brands fa-docker" title="Docker" desc="Docker를 이용하여 몇 가지 가상화 환경을 구축해 보았습니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-600 text-white" progress="10" />
                            <Skillcard icon="fa-brands fa-aws" title="AWS" desc="AWS를 이용하여 서버를 구축하고, 서버 간 통신을 구현할 수 있습니다." styleClass="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white" progress="15" />
                        </div>
                        <span className="font-bold text-xl mr-4">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray' }}><img src="https://i.imgur.com/SyqR5I1.png" className="inline-block max-h-10" /></span>
                        <p className="w-full mb-8 mt-2">Google Product Expert는 Google의 공식 커뮤니티 전문가 프로그램입니다. 아래 분야에서 활동하고 있으며, Google Product Expert 프로그램 앰배서더로도 활동중입니다. Google 제품 사용자들을 돕고, 일부 커뮤니티에 대해 추가적인 권한을 가지며 관리 또한 돕고 있습니다.</p>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard iconImage="https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_White_512px.png" title="Gmail" isVerified desc="한국 및 미국(영어) Gmail 지원 커뮤니티에서 Gold Product Expert로 활동 중입니다." styleClass="bg-gradient-to-r from-red-500 to-blue-500 text-white" progress="90" />
                            <Skillcard icon="fa-brands fa-youtube" title="YouTube" isVerified desc="YouTube 지원 커뮤니티에서 Silver Product Expert 및 YouTube Contributor로 활동 중입니다." styleClass="bg-gradient-to-r from-red-500 to-red-600 text-white" progress="90" />
                            {/* <Skillcard icon="fa-brands fa-google" title="Google 계정" isVerified desc="Google 계정 영문 지원 커뮤니티에서 Bronze Product Expert로 활동 중입니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-300 text-white" progress="80" /> */}
                        </div>
                        <span className="font-bold text-xl mr-2">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>Artificial Intelligence</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="fa-brands fa-apple" title="Core ML" desc="Core ML을 이용하여 iOS 애플리케이션에 인공지능 기능을 추가할 수 있습니다." styleClass="bg-gradient-to-r from-green-500 to-blue-500 text-white" progress="15" />
                            <Skillcard iconImage="https://www.tensorflow.org/static/site-assets/images/marketing/icon/icon-all-tensorflow-white.png?hl=ko" title="TensorFlow" desc="TensorFlow를 이용하여 인공지능 모델을 구축하고, 학습시킬 수 있습니다." styleClass="bg-gradient-to-r from-orange-500 to-red-400 text-white" progress="30" />
                            <Skillcard icon="fa-brands fa-python" title="Scikit-Learn" desc="Scikit-Learn을 이용하여 인공지능 모델을 구축하고, 학습시킬 수 있습니다." styleClass="bg-gradient-to-r from-orange-400 to-blue-600 text-white" progress="40" />
                        </div>
                        <span className="font-bold text-xl mr-2">Tech Stacks</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>SEO & Analysis</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="bi bi-bar-chart-fill" title="Google Analytics" desc="Google Analytics를 이용하여 웹 사이트의 트래픽 및 사용자 행동을 분석할 수 있습니다." styleClass="bg-gradient-to-r from-[#ed750a] to-[#ffc517] text-white" progress="40" />
                            <Skillcard icon="fa-brands fa-google" title="Google Search Console" desc="Google Search Console을 이용하여 웹 사이트의 검색 엔진 최적화를 진행할 수 있습니다." styleClass="bg-gradient-to-r from-slate-500 to-gray-400 text-white" progress="35" />
                            <Skillcard icon="bi bi-tag-fill" title="Google Tag Manager" desc="Google Tag Manager를 이용하여 웹 사이트의 트래픽 및 사용자 행동을 분석할 수 있습니다." styleClass="bg-gradient-to-r from-[#4284F4] to-[#50C4F7] text-white" progress="20" />
                        </div>
                        <span className="font-bold text-xl mr-2">Design</span>
                        <span className="text-gray-500 text-xl font-light" style={{ borderLeft: '1px solid gray', paddingLeft: '8px' }}>UX/UI & Creative Tools</span>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <Skillcard icon="fa-brands fa-figma" title="Figma" desc="Figma를 이용하여 앱 및 웹 UX/UI 디자인 및 협업을 진행하였습니다. 프로토타입 제작이 가능합니다." styleClass="bg-gradient-to-r from-purple-500 to-red-500 text-white" progress="55" />
                            <Skillcard iconImage="https://i.imgur.com/HbUFf3q.png" title="Adobe Photoshop" isVerified desc="Adobe Photoshop 자격증을 보유하고 있으며, 로고 및 포스터 등 다양한 작업을 진행할 수 있습니다." styleClass="bg-gradient-to-r from-blue-500 to-blue-800 text-white" progress="92" />
                            <Skillcard iconImage="https://i.imgur.com/HbUFf3q.png" title="Adobe XD" desc="Adobe XD를 이용하여 UX/UI 디자인 및 공동 작업을 진행한 경험이 있습니다." styleClass="bg-gradient-to-r from-pink-800 to-pink-500 text-white" progress="20" />
                            <Skillcard icon="fa-brands fa-apple" title="Final Cut Pro" desc="Final Cut Pro를 이용하여 영상 편집을 진행할 수 있습니다." styleClass="bg-gradient-to-r from-red-500 to-red-600 text-white" progress="60" />
                            <Skillcard icon="fa-brands fa-apple" title="Logic Pro" desc="Logic Pro를 이용하여 음악 제작을 진행할 수 있습니다." styleClass="bg-gradient-to-r from-slate-600 to-slate-400 text-white" progress="60" />
                            <Skillcard iconImage="https://i.imgur.com/HbUFf3q.png" title="Adobe Premiere Pro" desc="Adobe Premiere Pro를 이용하여 영상 편집을 진행할 수 있습니다." styleClass="bg-gradient-to-r from-purple-800 to-blue-400 text-white" progress="60" />
                            <Skillcard iconImage="https://i.imgur.com/HbUFf3q.png" title="Adobe Illustrator" desc="Adobe Illustrator를 이용하여 로고 등의 작업을 진행할 수 있습니다." styleClass="bg-gradient-to-r from-yellow-800 to-yellow-600 text-white" progress="15" />
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="press" className="bg-white py-20 min-h-screen">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full justify-center items-start text-center md:text-left" onClick={() => window.location.href = "https://www.ggilbo.com/news/articleView.html?idxno=858991"} style={{ cursor: 'pointer' }}>
                        <h2 className="text-3xl font-bold mb-8">Press</h2>
                        <div className="w-full justify-center items-start text-center md:text-left grid grid-cols-1 md:grid-cols-3">
                            <img src="https://cdn.ggilbo.com/image/logo/printlogo_20200915105312.png" className="inline-block w-full rounded-lg shadow-md py-4 md:py-16 mb-4 md:mb-0 px-4" />
                            <div className="col-span-2 px-10">
                                <h3 className="text-xl font-bold mb-4">[창의융합인재 키우는 노벨과학 교육] “목표 향해 인내하며 연구한 결과”</h3>
                                <p className="text-gray-700 text-base mb-2">금강일보 | 이준섭 기자</p>
                                <p className="text-gray-500 text-base mb-2">임 군은 “노벨상 수상에 필요한 기초과학 역량과 후속연구를 조사하고 연구하는 과정은 소중한 경험이었다”며 “특히 노벨과학동아리에서 연구와 조사를 지속해 오면서 배운 인내력, 창의력도 키워보는 등을 이번 대회를 통해 직접 연결시켜 보는 귀한 시간이었다”고 흡족해했다.</p>
                                <a href="https://www.ggilbo.com/news/articleView.html?idxno=858991" target="_blank" className="text-blue-500 hover:text-blue-600"><i className="bi bi-newspaper mr-2"></i>기사 보기 <i className="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="footer" className="bg-slate-900 py-20">
                <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full justify-center items-start text-center md:text-left">
                        <h2 className="text-3xl font-bold mb-4 text-white">JeongHoon Lim</h2>
                        <p className="w-full text-white">A passionate, creative entrepreneur & developer.</p>
                        <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="flex flex-row items-center justify-center md:justify-start">
                                <p className="text-white text-lg">
                                    <a href="https://mailhide.io/e/B2zyI8sp" target="_blank" className="text-white hover:text-blue-200">
                                        <i className="bi bi-envelope text-white text-2xl mr-4"></i>
                                    </a>
                                </p>
                                <p className="text-white text-lg">
                                    <a href="https://instagram.com/_jh_0105" target="_blank" className="text-white hover:text-blue-200">
                                        <i className="bi bi-instagram text-white text-2xl mr-4"></i>
                                    </a>
                                </p>
                                <p className="text-white text-lg">
                                    <a href="https://linkedin.com/in/hoony6134" target="_blank" className="text-white hover:text-blue-200">
                                        <i className="bi bi-linkedin text-white text-2xl mr-4"></i>
                                    </a>
                                </p>
                                <p className="text-white text-lg">
                                    <a href="https://github.com/hoony6134" target="_blank" className="text-white hover:text-blue-200">
                                        <i className="bi bi-github text-white text-2xl mr-4"></i>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <p className="text-white text-base">© 2024 JeongHoon Lim. All Rights Reserved.</p>
                        <br />
                        {/* <p className="text-gray-400 text-xs">Last edit: {lastDeployedTime}</p> */}
                        <p className="text-gray-400 text-xs">Last edit: 2024. 6. 30.</p>
                    </div>
                </div>
            </Element>
            {sidebarOpen ?
                <div id="sidebar" className="fixed md:top-1/2 bottom-2 left-1/2 md:left-auto md:right-2 -translate-y-1/2 -translate-x-1/2 z-50 bg-slate-200 opacity-80 md:opacity-50 md:hover:opacity-80 shadow-md rounded-full">
                    <div className="flex flex-row md:flex-col items-center">
                        <ScrollLink to="home" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-chevron-up text-black"></i></div>
                        </ScrollLink>
                        <ScrollLink to="groups" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-people-fill text-black"></i></div>
                        </ScrollLink>
                        <ScrollLink to="socials" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-at text-black"></i></div>
                        </ScrollLink>
                        <ScrollLink to="projects" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-code-slash text-black"></i></div>
                        </ScrollLink>
                        <ScrollLink to="stacks" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-laptop text-black"></i></div>
                        </ScrollLink>
                        <ScrollLink to="press" smooth={true} duration={500} offset={0} spy={true} activeClass="active" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-newspaper text-black"></i></div>
                        </ScrollLink>
                        <Link to="/contact" className="px-3 md:px-6 py-3 rounded-full font-semibold text-white hover:text-blue-200">
                            <div><i className="bi bi-send-fill text-black"></i></div>
                        </Link>
                        <button className="px-3 md:px-6 py-1 rounded-full font-semibold text-white hover:text-blue-200" onClick={toggleSidebar}><i className="bi bi-arrows-collapse md:bi-chevron-right text-black"></i></button>
                    </div>
                </div>
                :
                <div className="fixed md:top-1/2 bottom-2 left-1/2 md:left-auto -translate-x-1/2 md:right-2 transform z-50 w-full md:w-auto"><div className="flex flex-row md:flex-col text-right items-end justify-end"><button className="p-5 bg-slate-300 opacity-80 md:opacity-50 md:hover:opacity-80 rounded-full font-semibold text-white hover:text-blue-200 mr-2" onClick={toggleSidebar}><i className="bi bi-list text-black"></i></button></div></div>
            }
        </div>
    );
}

export default Home;