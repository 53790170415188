const Notfound = () => {
    return (
        <>
            <div className="not-found bg-red-50 w-full h-full p-10">
                <div className="bg-red-100 p-10">
                    <div className="bg-red-200 p-16">
                        <i className="bi bi-exclamation-octagon-fill font-bold text-red-600 text-5xl mb-4"></i>
                        <h1 className="font-bold text-red-600 mb-2 mt-4">404 Not Found</h1>
                        <p className="font-light text-xl text-red-800">Sorry, but I was stuck in void while processing your command.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notfound;