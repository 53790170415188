import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Home'
import Nav from './Nav'
import Project from './Project'
import Notfound from './Notfound'
import Contact from './Contact'

const Structures = () => {
    return (
        <>
            <BrowserRouter>
            <Nav />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Project/:id" element={<Project />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default Structures;