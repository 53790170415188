import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <nav style={{ position: 'fixed', top: 0, left: 0, right: 0, background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 100%)', zIndex: 100 }}>
            <div className="container flex justify-between items-center py-4 mx-auto">
                <Link to="/" className="font-bold text-2xl ml-4 text-gray-800 hover:text-blue-900">JeongHoon Lim</Link>
                <ul className="flex items-center">
                    <ul><Link to="/" className="p-4 text-gray-800 hover:text-gray-500">Home</Link></ul>
                    <ul><Link to="/contact" className="p-4 text-gray-800 hover:text-gray-500 mr-2">Contact</Link></ul>
                </ul>
            </div>
        </nav>
    );
}

export default Nav;
