import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup script on component unmount
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="bg-white py-20 min-h-screen">
            <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
                <div className="w-full justify-center items-start text-center md:text-left">
                    <h2 className="text-3xl font-bold mb-4">연락하기</h2>
                    <p className="mb-4">문의 사항이 있으시거나, 각종 제안의 경우 아래 이메일 혹은 SNS, 문의 폼을 통해 작성해 주시면 빠른 시일 내에 연락드리겠습니다.</p>
                    <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="max-w-sm rounded overflow-hidden shadow-lg">
                            <div className="px-6 py-6">
                                <div className="font-bold text-xl mb-2">이메일 문의</div>
                                <p className="text-gray-700 text-base">
                                    <span className="text-gray-700 text-base">
                                        <i className="bi bi-envelope mr-2"></i>
                                        <a href="https://mailhide.io/e/Mh6ZxcYq" className="text-black" target="_blank" rel="noreferrer">
                                            <span className="hover:text-blue-500">
                                                프로젝트 및 제품 관련 문의
                                                <i className="bi bi-chevron-right ml-1"></i>
                                            </span>
                                        </a>
                                        <br />
                                        <i className="bi bi-envelope mr-2"></i>
                                        <a href="https://mailhide.io/e/3tfxdwld" className="text-black" target="_blank" rel="noreferrer">
                                            <span className="hover:text-blue-500">
                                                개인 및 사업 제안, 기타 문의
                                                <i className="bi bi-chevron-right ml-1"></i>
                                            </span>
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="max-w-sm rounded overflow-hidden shadow-lg">
                            <div className="px-6 py-6">
                                <div className="font-bold text-xl mb-2">SNS 채널</div>
                                <p className="text-gray-700 text-base">
                                    <span className="text-gray-700 text-base">
                                        <i className="fa-brands fa-instagram mr-2 text-pink-600"></i>
                                        <a href="https://instagram.com/_jh_0105" className="text-pink-600">
                                            <span className="hover:text-pink-700">
                                                Instagram Direct
                                                <i className="bi bi-chevron-right ml-1"></i>
                                            </span>
                                        </a>
                                        <br />
                                        <i className="fa-brands fa-linkedin mr-2 text-blue-600"></i>
                                        <a href="https://linkedin.com/in/hoony6134" className="text-blue-600">
                                            <span className="hover:text-blue-700">
                                                LinkedIn 메시지
                                                <i className="bi bi-chevron-right ml-1"></i>
                                            </span>
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="max-w-sm rounded overflow-hidden shadow-lg">
                            <div className="px-6 py-6">
                                <div className="font-bold text-xl mb-2">커피챗</div>
                                <p className="text-gray-700 text-base">
                                    <span className="text-gray-700 text-base">
                                        <i className="bi bi-chat-right-text mr-2"></i>
                                        <a href="https://calendly.com/jeonghoon" className="text-black" target="_blank" rel="noreferrer">
                                            <span className="hover:text-blue-500">
                                                Calendly
                                                <i className="bi bi-chevron-right ml-1"></i>
                                            </span>
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Tally Form */}
            <div className="container mx-auto px-6 mt-8">
                <iframe
                    data-tally-src="https://tally.so/embed/3xMMkJ?alignLeft=1&transparentBackground=1&dynamicHeight=1"
                    loading="lazy"
                    width="100%"
                    height="200"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    title="Contact Me"
                ></iframe>
            </div>
        </div>
    );
}

export default Contact;
