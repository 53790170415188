import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Structures from './Structures';
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <>
      <Structures />
    </>
  );
}

export default App;
