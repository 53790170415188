import { Link } from 'react-router-dom';
import { useState } from 'react';

const Projectcard = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const imageUrl = props.image.startsWith('https://i.imgur.com/') ? props.image.replace(/(\.[^.]+)$/, 'm$1') : props.image;

    return (
        <div style={{ transform: isHovered ? 'scale(1.03)' : 'scale(1)' }} className="flex flex-col items-center justify-center w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg relative w-full">
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#f0f0f0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-200"
                        >
                            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-slate-400"></div>
                        </div>
                    </div>
                )}
                {props.featured ? (
                    <div className="absolute top-2 right-2">
                        <div className="bg-white bg-opacity-80 rounded-lg p-1 px-2 text-blue-500 font-semibold">
                            <i className="bi bi-star-fill text-blue-500 mr-1"></i>Featured
                        </div>
                    </div>
                ) : ""}
                <img
                    className="h-40 object-center object-cover w-full"
                    src={imageUrl}
                    alt={props.name}
                    onLoad={handleImageLoad}
                    style={{ display: isLoading ? 'none' : 'block' }}
                />
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">
                        {props.name}
                        {props.isVerified ? <i className="bi bi-patch-check-fill text-lg ml-1 text-blue-500"></i> : ""}
                    </div>
                    <p className="text-gray-700 text-base">
                        <div className="description w-full whitespace-pre-line break-keep text-gray-700 text-base" style={{ maxHeight: '3.2em', overflow: 'hidden' }}>
                            {props.description}
                        </div>


                        <div className="lg:flex md:hidden sm:flex hidden mt-2 mb-1">
                            {props.stacks &&
                                props.stacks.split(',').map((stack, index) => {
                                    // Remove leading and trailing whitespace
                                    stack = stack.trim();
                                    // Skip if stack is empty
                                    if (!stack) return null;
                                    if (stack.length > 5) return null;
                                    // Render stack as a badge
                                    return (
                                        <span
                                            key={index}
                                            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                                        >
                                            {stack}
                                        </span>
                                    );
                                })
                                    .filter(Boolean) // Remove any null elements
                                    .slice(0, 3) // Take the first three stacks
                            }
                            {props.stacks && props.stacks.split(',').filter(stack => stack.trim().length > 5).length > 0 && (
                                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-500 mr-2">
                                    +{props.stacks.split(',').length - props.stacks.split(',').filter(stack => stack.trim().length <= 5).length}
                                </span>
                            )}
                        </div>

                    </p>




                </div>
                {isHovered && (
                    <div className="overlay absolute inset-0 w-full h-full" style={{ transition: 'opacity 0.3s' }}>
                        <div className="overlay-content w-full h-full flex items-center justify-center">
                            <Link to={'/project/' + props.id} className="px-3 py-1 text-md font-semibold w-full h-full flex items-center justify-center" style={{ backgroundColor: "rgba(112, 128, 144, 0.8)", color: "white" }}>
                                <i className="bi bi-box-arrow-up-right mr-2"></i>프로젝트 자세히 보기
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Projectcard;
