const Groupcard = ({ groupName, groupDescription, imgLink, link, style }) => {
    const handleClick = () => {
        window.open(link, "_blank");
    };

    return (
        <div>
            <div className={`groupcard rounded-lg ${style} p-4 shadow-xl hover:shadow-2xl hover:scale-105 transition-transform duration-300 h-20 mb-3`} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={imgLink} alt={groupName} className="max-h-12"/>
            </div>
            <h4 className="font-bold mt-2">{groupName}</h4>
            <span className="text-sm text-gray-500">{groupDescription}</span>
        </div>
    );
}

export default Groupcard;