import React, { useState } from "react";

const Certcard = ({ title, issuer, style, issuerimg, skillname, skillicon, desc }) => {
    const [cardStyle, setCardStyle] = useState({});

    const handleMouseMove = (e) => {
        const card = e.currentTarget;
        const cardRect = card.getBoundingClientRect();
        const cardCenterX = cardRect.left + cardRect.width / 2;
        const cardCenterY = cardRect.top + cardRect.height / 2;
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const rotateX = (mouseY - cardCenterY) / 20;
        const rotateY = (cardCenterX - mouseX) / 20;

        setCardStyle({
            transform: `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`,
        });
    };

    const handleMouseLeave = () => {
        setCardStyle({});
    };

    return (
        <div
            className={`certcard rounded-lg ${style} p-4 shadow-xl mb-3 relative text-left`}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={cardStyle}
        >
            <img src={issuerimg} alt="Issuer" className="absolute top-6 right-6 max-w-7 max-h-7" />
            <i className="absolute bottom-4 right-4 bi bi-award-fill text-gray-500 text-xl"></i>
            <h4 className="font-bold mt-2 max-w-60">{title}</h4>
            <span className="text-sm text-gray-500 mb-4 h-full">{issuer}</span><i className="bi bi-patch-check-fill ml-1 text-sm text-blue-500"></i>
            <div className="flex flex-row items-center justify-start mt-2">
                <i className={`${skillicon} text-md`}></i>
                <span className="text-sm ml-2">{skillname}</span>
                <span className="text-sm ml-2 text-gray-500">{desc}</span>
            </div>
        </div>
    );
};

export default Certcard;
