const Socialcard = ({ icon, iconLink, title, label, link, bgColor, verified, verifiedStyle }) => {
    const handleClick = () => {
        window.open(link, "_blank");
    };

    return (
        <div>
            <div className={`socialcard rounded-lg ${bgColor} p-4 shadow-xl hover:shadow-2xl hover:scale-105 transition-transform duration-300 h-20 mb-3`} onClick={handleClick} style={{ cursor: 'pointer' }}>
                {iconLink ? (
                    <img src={iconLink} alt={title} className="w-8 h-8"/>
                ) : (
                    <i className={`${icon} text-white text-3xl`}></i>
                )}
            </div>
            <h4 className="font-bold mt-2">{title}
            <div className={`checkmark inline`}>
                {verified && <i className={`bi bi-patch-check-fill ml-1 text-lg ${verifiedStyle}`}></i>}
            </div></h4>
            <span className="text-sm text-gray-500">{label}</span>
        </div>
    );
}

export default Socialcard;