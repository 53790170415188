import { useState, useEffect } from "react";
import Projectcard from "./Projectcard";

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch("https://api.toolbox.scian.xyz/getProjectList")
            .then(response => response.json())
            .then(data => setProjects(data.projectData))
            .catch(error => console.log(error));
    }, []);

    return (
        <div>
            <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-start text-center md:text-left">
                <div className="w-full">
                    <h2 className="text-3xl font-bold mb-4">프로젝트</h2>
                    <p className="mb-4">제가 진행한 프로젝트의 목록입니다. 프로젝트는 수시로 업데이트됩니다.</p>
                    <div className="flex-wrap justify-center mt-4 mb-8 grid grid-cols-1 md:grid-cols-3 gap-8">
                        {projects.length === 0 ? (
                            <p>프로젝트 목록을 불러오는 중입니다..</p>
                        ) : (
                            Array.isArray(projects) && projects.reverse().map(project => (
                                project.isFeatured ? (
                                    <Projectcard key={project.id} id={project.id} name={project.title} description={project.headline} image={project.imgURL} isVerified={project.isVerified} featured={project.isFeatured} stacks={project.stacks} />
                                ) : null
                            ))
                        )}
                        {projects.length !== 0 && Array.isArray(projects) && projects.reverse().map(project => (
                            !project.isFeatured ? (
                                <Projectcard key={project.id} id={project.id} name={project.title} description={project.headline} image={project.imgURL} isVerified={project.isVerified} featured={project.isFeatured} stacks={project.stacks} />
                            ) : null
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Projects;