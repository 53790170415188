import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Project = () => {
    let { id } = useParams();
    const [projectData, setProjectData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://api.toolbox.scian.xyz/getProject?id=${id}`);
                console.log(response.status);
                if (response.status === 404) {
                    setLoading(false);
                    setNotFound(true);
                    return;
                }
                const data = await response.json();
                if (!data.success) throw new Error(data.message);
                setProjectData(data.projectData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const stacks = projectData ? projectData.stacks.split(',') : [];
    const isVerified = projectData ? projectData.isVerified === 1 : 0;

    const stackStyles = {
        Swift: {
            background: 'linear-gradient(to right, #FF7518, #FF3C6E)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-swift mr-1',
        },
        Web: {
            background: 'linear-gradient(to right, #56CCF2, #2F80ED)',
            labelColor: '#fff',
            logoIcon: 'bi bi-globe mr-1',
        },
        iOS: {
            background: 'linear-gradient(to right, #FF7518, #FF3C6E)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-apple mr-1',
        },
        Android: {
            background: 'linear-gradient(to right, #3DDC84, #00A9FF)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-android mr-1',
        },
        TensorFlow: {
            background: 'linear-gradient(to right, #FF6A00, #FFA500)',
            labelColor: '#fff',
            logoIcon: 'devicon-tensorflow-original mr-1',
        },
        Arduino: {
            background: 'linear-gradient(to right, #00979D, #00B4AB)',
            labelColor: '#fff',
            logoIcon: 'devicon-arduino-plain mr-1',
        },
        Bootstrap: {
            background: 'linear-gradient(to right, #563D7C, #6F42C1)',
            labelColor: '#fff',
            logoIcon: 'bi bi-bootstrap-fill mr-1',
        },
        화학: {
            background: 'linear-gradient(to right, #FDC830, #F37335, #8A2BE2)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-flask mr-1',
        },
        생명과학: {
            background: 'linear-gradient(to right, #0099A0, #00EE32)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-dna mr-1',
        },
        수학: {
            background: 'linear-gradient(to right, #FF1000, #FF4200, #FFA100)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-square-root-alt mr-1',
        },
        '3D 디자인': {
            background: 'linear-gradient(to right, #4568DC, #B06AB3',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-cube mr-1',
        },
        'Fusion 360': {
            background: 'linear-gradient(to right, #FF6A00, #FFA500)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-cube mr-1',
        },
        Inventor: {
            background: 'linear-gradient(to right, #FFB75E, #ED8F03)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-cube mr-1',
        },
        환경: {
            background: 'linear-gradient(to right, #76b852, #8DC26F',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-leaf mr-1',
        },
        SNS: {
            background: 'linear-gradient(to right, #FF512F, #DD2476',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-hashtag mr-1',
        },
        Linux: {
            background: 'linear-gradient(to right, #F9D423, #FF4E50',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-linux mr-1',
        },
        'Node.js': {
            background: 'linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-node-js mr-1',
        },
        MySQL: {
            background: 'linear-gradient(to right, #005AA7, #F7971E)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-database mr-1',
        },
        'Google Cloud Platform': {
            background: 'linear-gradient(to right, #4285F4, #EA4335, #F8BC06, #34A853)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-google mr-1',
        },
        Python: {
            background: 'linear-gradient(to right, #ccbb00, #2392ed)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-python mr-1',
        },
        'C++': {
            background: 'linear-gradient(to right, #005AA7, #0077E4)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-cuttlefish mr-1',
        },
        AI: {
            background: 'linear-gradient(to right, #FF512F, #DD2476)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-brain mr-1',
        },
        '머신러닝': {
            background: 'linear-gradient(to right, #CC992F, #EE2476)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-robot mr-1',
        },
        JavaScript: {
            background: 'linear-gradient(to right, brown, #ccbb00)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-js mr-1',
        },
        Chrome: {
            background: 'linear-gradient(to right, #4285F4, #EA4335, #F8BC06, #34A853)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-chrome mr-1',
        },
        '브라우저 확장': {
            background: 'linear-gradient(to right, #92ba82, #7298ee)',
            labelColor: '#fff',
            logoIcon: 'fa-solid fa-puzzle-piece mr-1',
        },
        'Final Cut Pro': {
            background: 'linear-gradient(to left, #DD9E52, #DD2476)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-apple mr-1',
        },
        React: {
            background: 'linear-gradient(to right, #61DAFB, #61DAFB)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-react mr-1',
        },
        Figma: {
            background: 'linear-gradient(to right, #F24E1E, #FF7300)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-figma mr-1',
        },
        LLM: {
            background: 'linear-gradient(to right, #59C173, #a17fe0, #5D26C1)',
            labelColor: '#fff',
            logoIcon: 'bi bi-blockquote-left mr-1',
        },
        지구과학: {
            background: 'linear-gradient(to right, #6D6027, #D3CBB8)',
            labelColor: '#fff',
            logoIcon: 'bi bi-crosshair2 mr-1',
        },
        통신: {
            background: 'linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)',
            labelColor: '#fff',
            logoIcon: 'bi bi-telephone-fill mr-1',
        },
        'Wi-Fi': {
            background: 'linear-gradient(to right, #CB356B, #BD3F32)',
            labelColor: '#fff',
            logoIcon: 'bi bi-wifi mr-1',
        },
        'Core ML': {
            background: 'linear-gradient(to right, #4AC29A, #BDFFF3)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-apple mr-1',
        },
        음악: {
            background: 'linear-gradient(to right, #f2709c, #ff9472)',
            labelColor: '#fff',
            logoIcon: 'bi bi-music-note-list mr-1',
        },
        'Logic Pro': {
            background: 'linear-gradient(to right, #2c3e50, #bdc3c7)',
            labelColor: '#fff',
            logoIcon: 'fa-brands fa-apple mr-1',
        },
        국어: {
            background: 'linear-gradient(to right, #4b6cb7, #182848)',
            labelColor: '#fff',
            logoIcon: 'bi bi-book-half mr-1',
        },
        'UI/UX': {
            background: 'linear-gradient(to right, #ff00cc, #333399)',
            labelColor: '#fff',
            logoIcon: 'bi bi-ui-checks mr-1',
        },
        디자인: {
            background: 'linear-gradient(to right, #ee0979, #ff6a00)',
            labelColor: '#fff',
            logoIcon: 'bi bi-palette-fill mr-1',
        },
        비즈니스모델: {
            background: 'linear-gradient(to right, #2980b9, #2c3e50)',
            labelColor: '#fff',
            logoIcon: 'bi bi-briefcase-fill mr-1',
        },
    };
    const renderBody = () => {
        if (loading) {
            return (
                <div className="loading bg-gray-200 w-full h-full p-10 flex flex-col">
                    <h1 className="font-bold mb-4">불러오는중..</h1>
                    <p>프로젝트를 불러오고 있습니다..</p>
                </div>
            );
        } else if (notFound) {
            return (
                <div className="not-found bg-red-50 w-full h-full p-10">
                    <div className="bg-red-100 p-10">
                        <div className="bg-red-200 p-16">
                            <i className="bi bi-exclamation-octagon-fill font-bold text-red-600 text-5xl mb-4"></i>
                            <h1 className="font-bold text-red-600 mb-2 mt-4">프로젝트를 찾을 수 없습니다 :(</h1>
                            <p className="font-light text-xl">요청하신 페이지를 찾을 수 없습니다. 아마 <i className="text-blue-800">*언젠가는*</i> 보실 수 있을 거에요 :)</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className="bg-slate-200 w-full h-16"></div>
                    <header className="w-full h-full">
                        <div className="w-full">
                            <div className="flex flex-col justify-left items-left md:px-36 bg-gradient-to-b from-slate-200 to-white">
                                <div className="px-6 md:px-0">
                                    <span className="font-semibold text-2xl mb-2 mt-4">{projectData.title} {isVerified ? (
                                        <i className="bi bi-patch-check-fill text-blue-500"></i>
                                    ) : null}</span>
                                    <p className="text-gray-500">{projectData.headline}</p>

                                    {projectData.award ? (
                                        <div className="mb-4 mt-2 text-yellow-600 font-semibold">
                                            <i className="bi bi-award mr-1"></i>
                                            {projectData.award}
                                        </div>
                                    ) : null}
                                    <i className="bi bi-calendar3 mr-2"></i>
                                    <span>{projectData.date}</span>
                                    <div className="flex flex-row justify-left mt-4 items-left">
                                        {projectData.link ?
                                            (<a className="text-blue-500 hover:text-blue-800 border-blue-500 hover:border-blue-800 hover:bg-blue-100 rounded-lg border px-2 py-1.5 mt-4 items-center justify-center mr-2" href={projectData.link} target="_blank" rel="noreferrer">
                                                <i className="bi bi-link-45deg mr-1"></i>
                                                {projectData.linkname ? projectData.linkname : "Link"}
                                            </a>) : null}
                                        <div className="status-badge flex flex-wrap px-2 py-1.5 rounded-md mt-4 items-center justify-center"
                                            style={{
                                                color: projectData.status_style === 1 ? 'rgba(0, 128, 0, 0.6)' : projectData.status_style === 2 ? 'rgba(0, 128, 0, 0.6)' : projectData.status_style === 3 ? 'rgba(255, 140, 0, 0.6)' : 'rgba(255, 0, 0, 0.6)',
                                            }}
                                        >
                                            <strong>
                                                {projectData.status}
                                            </strong>
                                            {projectData.status_style === 1 && <i className="bi bi-patch-check-fill ml-1"></i>}
                                            {projectData.status_style === 2 && <i className="bi bi-check ml-1"></i>}
                                            {projectData.status_style === 3 && <i className="fa-solid fa-hammer ml-1 fa-flip-horizontal"></i>}
                                            {projectData.status_style === 0 && <i className="bi bi-x ml-1"></i>}
                                        </div>
                                    </div>
                                    <button
                                        onClick={handleShowDescription}
                                        className="text-blue-500 hover:text-blue-800 mt-4 items-left justify-left mb-4 flex"
                                    >
                                        <i className={showDescription ? "bi bi-chevron-up mr-1" : "bi bi-chevron-down mr-1"}></i>
                                        {showDescription ? "간략히 보기" : "자세히 보기"}
                                    </button>
                                    {showDescription && (
                                        <div className="description mt-4">
                                            <div className="flex flex-wrap justify-left items-left mb-4">

                                                <div className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold border border-gray-600 text-gray-700 mr-2 mb-2">
                                                    <i className="bi bi-tag-fill mr-1"></i>태그
                                                </div>
                                                {stacks.map((stack, index) => {
                                                    const stackStyle = stackStyles[stack] || {}; // Use the stack style if available, otherwise use default style
                                                    return (
                                                        <>
                                                            <div
                                                                key={index}
                                                                className={`bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`}
                                                                style={{ background: stackStyle.background, color: stackStyle.labelColor }}
                                                            >
                                                                <i className={stackStyle.logoIcon}></i>
                                                                {stack}
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="w-full h-full flex text-left">
                        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                            <div className="absolute inset-0 -z-10 overflow-hidden">
                                <svg className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
                                    <defs>
                                        <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                                        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
                                    </svg>
                                    <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                                </svg>
                            </div>

                            <div className="absolute inset-0 -z-10 overflow-hidden">
                                <svg className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
                                    <defs>
                                        <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                                        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
                                    </svg>
                                    <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                                </svg>
                            </div>
                            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                    <div className="lg:pr-4">
                                        <div className="lg:max-w-lg">
                                            <p className="text-base font-semibold leading-7 text-indigo-600">{projectData.eng_sub}</p>
                                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{projectData.title}</h1>
                                            <p className="mt-6 text-xl leading-8 text-gray-700 break-keep">{projectData.top_desc}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                                    <img className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" src={projectData.imgURL} alt="" />
                                </div>
                                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                                    <div className="lg:pr-4">
                                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg break-keep">
                                            <div dangerouslySetInnerHTML={{ __html: projectData.html }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            {/* <header className="flex flex-col items-center justify-center min-h-screen text-center"> */}
            {renderBody()}
            {/* </header> */}
        </>
    );
}

export default Project;
